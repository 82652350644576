let musicDB = [
    {
        id: 0,
        name: "Beggin",
        author_name: "Maneskin",
        img: "Madcon - Beggin.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type: "electronic",
        musicName: "Maneskin -Beggin.mp3",
    },
    {
        id: 1,
        name: "Sexy Bitch (feat. Akon)",
        author_name: "David Guetta",
        img: "Sexy Bitch.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type:"electronic",
        musicName: "Sexy Bitch (feat. Akon).mp3",
    },
    {
        id: 2,
        name: "Seven Nation Army - The White Stripes (Where Its ATT Remix)",
        author_name: "Saint Beatz",
        img: "Seven nation army.jpg",
        lang: "English",
        type: "rock",
        timesPlayed: 0,
        musicName: "Seven Nation Army - The White Stripes (Where Its ATT Remix).mp3",
    },
    {
        id: 3,
        name: "We Found Love",
        author_name: "Niklas Dee, BASTL, MORRES",
        img: "We found love.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type: "dance-pop",
        musicName: "We Found Love.mp3",
        
    },
    {
        id: 4,
        name: "I Believe",
        author_name: "KAMRAD",
        img: "i belive.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type: "pop",
        musicName: "KAMRAD - I Believe (Official Video).mp3",
    },
    {
        id: 5,
        name: "Can't Hold Us",
        author_name: "Macklemore & Ryan Lewis (feat. Ray Dalton)",
        img: "cant hold us.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type: "hip-hop",
        musicName: "Cant Hold Us - Macklemore  Ryan Lewis (feat. Ray Dalton).mp3",
    },
    {
        id: 6,
        name: "Timber feat. KE$HA",
        author_name: "Pitbull",
        img: "timber.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type:"dance-pop",
        musicName: "Timber.mp3",
    },
    {
        id: 7,
        name: "Where Have You Been",
        author_name: "Rihanna",
        img: "where have u been.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type:"pop",
        musicName: "Where Have You Been.mp3",
    },
    {
        id: 8,
        name: "Ignite",
        author_name: "Tom wilson, Heuse",
        img: "ignite-1600419626-nHn4WXY79q.jpg",
        lang: "ENGLISH",
        timesPlayed: 0,
        type: "electronic",
        musicName: "Heuse & Tom Wilson - Ignite [NCS Release].mp3",
    },
    {
      id: 9,
      name: "Don't Worry (with Ray Dalton) (Radio Edit)",
      author_name: "Madcon, Ray Dalton",
      img: "dont worry.jpg",
      lang: "ENGLISH",
      timesPlayed: 0,
      type: "dance-pop",
      musicName: "Dont Worry (with Ray Dalton) (Radio Edit).mp3",
  },
  {
    id: 10,
    name: "Nad ranem (prod. Opiat)",
    author_name: "Kabe",
    img: "nad ranem.jpg",
    lang: "POLISH",
    timesPlayed: 0,
    type: "rap",
    musicName: "Kabe - Nad ranem (prod. Opiat).mp3",
},
{
  id: 11,
  name: "Amerykańskie Teledyski",
  author_name: "OIO",
  img: "amerykanskie teledyski.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rap",
  musicName: "OIO - Amerykańskie Teledyski.mp3",
},
{
  id: 12,
  name: "Ostatnia nocka ale to DRILL",
  author_name: "Maciej Maleńczuk & Sentino feat. Yugopolis",
  img: "ostatnia nocka ale to drill.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "drill",
  musicName: "Ostatnia nocka ale to DRILL (Maciej Maleńczuk  Sentino feat. Yugopolis).mp3",
},
{
  id: 13,
  name: "Zawsze tam gdzie Ty",
  author_name: "Lady Pank",
  img: "zawsze tam gdzie ty.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Lady Pank - Zawsze tam gdzie Ty.mp3",
},
{
  id: 14,
  name: "Wehikuł Czasu - To Byłby Cud (2003 Remaster)",
  author_name: "Dżem",
  img: "wehikul czasu.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Wehikul Czasu - To Bylby Cud (2003 Remastered Version).mp3",
},
{
  id: 15,
  name: "Biała Armia",
  author_name: "Bajm",
  img: "biala armia.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Biala armia.mp3",
},
{
  id: 16,
  name: "Kryzysowa Narzeczona",
  author_name: "Lady Pank",
  img: "kryzysowa narzeczona.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Lady Pank - Kryzysowa Narzeczona.mp3",
},
{
  id: 17,
  name: "Paranoia",
  author_name: "DARIA",
  img: "paranoia.jpg",
  lang: "ENGLISH",
  timesPlayed: 0,
  type: "dance-pop",
  musicName: "Paranoia.mp3",
},
{
  id: 18,
  name: "Rampampam",
  author_name: "Minelli",
  img: "rampampam.jpg",
  lang: "ENGLISH",
  timesPlayed: 0,
  type: "dance-pop",
  musicName: "Minelli - Rampampam  Official Video.mp3",
},
{
  id: 19,
  name: "Hollywood",
  author_name: "LA Vision & Gigi D'Agostino",
  img: "hollywood.jpg",
  lang: "ENGLISH",
  timesPlayed: 0,
  type: "electronic",
  musicName: "LA Vision  Gigi DAgostino - Hollywood ( Official Lyric Video ).mp3",
},
{
  id: 20,
  name: " Locked Out Of Heaven (OFFICIAL DRILL REMIX)",
  author_name: "@ewancarterr",
  img: "locked out of heaven.jpg",
  lang: "ENGLISH",
  timesPlayed: 0,
  type: "drill",
  musicName: "Bruno Mars - Locked Out Of Heaven (OFFICIAL DRILL REMIX) Prod. ewancarterr.mp3",
},
{
  id: 21,
  name: "Nie Daj Się",
  author_name: "Doda",
  img: "nie daj się.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "pop",
  musicName: "Nie Daj Sie.mp3",
},
{
  id: 22,
  name: "Sexualna",
  author_name: "Mirami",
  img: "sexualna.jpg",
  lang: "ANY",
  timesPlayed: 0,
  type: "pop",
  musicName: "Sexualna.mp3",
},
{
  id: 23,
  name: "Agnieszka już dawno...",
  author_name: "Łzy",
  img: "agnieszka już dawno.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Agnieszka już dawno.mp3",
},
{
  id: 24,
  name: "Królowa Łez",
  author_name: "Agnieszka Chylińska",
  img: "królowa łez.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Królowa łez.mp3",
},
{
  id: 25,
  name: "Ona jest ze snu",
  author_name: "Ira",
  img: "ona jest ze snu.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Ira - Ona jest ze snu.mp3",
},
{
  id: 26,
  name: "Lubiła Tanczyć",
  author_name: "Rotary",
  img: "lubila tanczyc.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "pop",
  musicName: "Rotary - Lubiła Tańczyć.mp3",
},
{
  id: 27,
  name: "Małe rzeczy",
  author_name: "Sylwia Grzeszczak",
  img: "male rzeczy.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "pop",
  musicName: "Sylwia Grzeszczak - Małe rzeczy.mp3",
},
{
  id: 28,
  name: "SENTINO X VIKI GABOR FT. ALBERTO",
  author_name: "typo g",
  img: "sentino x viki gabor.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "drill",
  musicName: "SENTINO X VIKI GABOR FT. ALBERTO.mp3",
},
{
  id: 29,
  name: "Danza Kuduro (DRILL SAMPLE REMIX)",
  author_name: "@eyex05",
  img: "danza kuduro.jpg",
  lang: "ANY",
  timesPlayed: 0,
  type: "drill",
  musicName: "Danza Kuduro (DRILL SAMPLE REMIX) eyex05.mp3",
},
{
  id: 30,
  name: "Byłaś dla Mnie Wszystkim",
  author_name: "Poparzeni Kawą Trzy",
  img: "bylas dla mnie wszystkim.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "rock",
  musicName: "Byłaś dla Mnie Wszystkim.mp3",
},
{
  id: 31,
  name: "Jak anioła głos",
  author_name: "Feel",
  img: "jak aniola glos.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "pop",
  musicName: "Jak Aniola Glos.mp3",
},
{
  id: 32,
  name: "She Doesn't Mind",
  author_name: "Krmoni",
  img: "she doesnt mind.jpg",
  lang: "ENGLISH",
  timesPlayed: 0,
  type: "dance-pop",
  musicName: "She Doesnt Mind.mp3",
},
{
  id: 33,
  name: "OCZY x DRILL",
  author_name: "prod/JK ft. Sanah",
  img: "oczy.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "drill",
  musicName: "SANAH - OCZY, ale to DRILL.mp3",
},
{
  id: 34,
  name: "Taxi",
  author_name: "Kizo ft. Bletka",
  img: "taxi.jpg",
  lang: "POLISH",
  timesPlayed: 0,
  type: "dance-pop",
  musicName: "Taxi.mp3",
},
];

export default musicDB;